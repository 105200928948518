.app-nav-buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 2%;
}

.app-nav-buttons h2 {
    padding-bottom: 1%;
    border-bottom: #c9c9c9c5 1px solid
}

.app-nav-buttons * {
    margin: 2%;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.update-log-link {
    margin-right: 1%;
    float: right;
    color: blue;
    font-weight: bold;
}

.update-log-link:hover {
    color: lightblue;
    cursor: pointer;
}

.app-nav-buttons .btn {
    background: rgb(255, 255, 255);
    color: #007dc0;
    border-color: #007dc0;
    box-shadow: 10px 5px 10px rgb(176, 176, 176);
    font-weight: bold;
}

.app-nav-buttons .btn:hover {
    background: rgb(240, 240, 240);
    color: #007dc0;
    border-color: rgb(205,205,205);
    
}

.app-nav-buttons * :nth-child(2) {
    font-size:150%;
}

.app-nav-buttons * * {
    margin: 1%;
    padding: 0;
}